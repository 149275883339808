import React from "react";

// import * as Constants from '../../config/Constants';

const MultiLineText=(props)=>{
    let text_filtered = props.text.replace(/\n/g, "<br />")
    return(
        <p
            dangerouslySetInnerHTML={{__html: text_filtered}} 
            style={{
                fontSize: 15,
                // fontFamily: 'Arial',
            }}
        />
    )
}
export default MultiLineText;
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";

import "../../../node_modules/aos/dist/aos.css";
import "../CustomeAccordion/Accordion.css";
import WorkflowDiagram from "../WorkflowDiagram";

AOS.init({
  once: true
});

function WorkflowSection() {
  return (
    <Container fluid className="home-section" id="challenges">
      <Container>
        <Row>
          <Col lg={6} className="sum4u-introduction-img"
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-delay="000"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '0px'
            }}
          >
            <div style={{ height: '100%', alignContent: 'center' }}>
              <WorkflowDiagram />
            </div>
          </Col>

          <Col lg={6} className="home-challenges-description"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-delay="000"
          >
            <h1 style={{ fontSize: "2.3em", paddingBottom: 10, color: "#fd7013" }}>
              AI Agent Framework
            </h1>
            <h1 style={{ fontSize: "1.45em", textAlign: 'left', padding: 10 }}>
              Our framework is a <span className="orange">versatile</span> solution tailored to meet 
              diverse industry needs. It comprises eight key modules, each designed to enhance business 
              outcomes.
            </h1>
            <h1 style={{ fontSize: "1.45em", textAlign: 'left', padding: 10, lineHeight: '1.3' }}>
              Utilizing this framework offers businesses a <span className="orange">comprehensive, adaptive </span> 
              solution with the following benefits:
              <ul>
                <li>Enhances customer engagement</li>
                <li>Streamlines operations</li>
                <li>Fosters continuous improvement</li>
                <li>Achieves higher efficiency</li>
                <li>Enables better decision-making</li>
                <li>Improves user satisfaction</li>
              </ul>
              This makes it an invaluable asset for any industry.
            </h1>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
export default WorkflowSection;
import React, { useState } from 'react';

const MultiLevelDataGraph = ({ targetElementId }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const styles = `
    .ai-agent-framework svg {
      max-width: 900px;
      width: 100%;
      height: 100%;
    }
    .ai-agent-framework text {
      font-weight: 300;
    }
    .ai-agent-framework .title {
      font-size: clamp(16px, 3.5vw, 32px);
      font-weight: 600;
      fill: #2c3e50;
    }
    .ai-agent-framework .level-title {
      font-size: clamp(28px, 3vw, 29px);
    }
    .ai-agent-framework .detail-text {
      font-size: clamp(24px, 2vw, 24px);
    }
    .ai-agent-framework .level-details {
      opacity: 0;
      transition: opacity 0.5s, transform 0.5s;
      transform: scale(0.9);
      pointer-events: none;
    }
    .ai-agent-framework g:hover > .level-details {
      opacity: 1;
      transform: scale(1);
    }
    .ai-agent-framework .connection {
      stroke-width: 2;
      fill: none;
      stroke-dasharray: 10, 10;
      animation: dash 10s linear infinite;
    }
    .ai-agent-framework .connection:hover {
      filter: url(#glow);
      stroke-width: 4;
      opacity: 1;
      stroke-dasharray: 20, 5;
      animation-duration: 20s;
    }
    @keyframes dash {
      to {
        stroke-dashoffset: 200;
      }
    }
    .ai-agent-framework .level-circle {
      transition: all 0.5s ease;
    }
    .ai-agent-framework g:hover > .level-circle {
      opacity: 0.1;
      transform: scale(1.03);
    }
    .ai-agent-framework .process-rect,
    .ai-agent-framework .benefits-rect {
      opacity: 0;
      transition: all 0.5s ease;
      transform: scale(0.9);
    }
    .ai-agent-framework g:hover > .process-rect,
    .ai-agent-framework g:hover > .benefits-rect {
      opacity: 1;
      transform: scale(1);
      animation: pulse-s 2s infinite alternate;
    }
    .ai-agent-framework text {
      font-weight: 300;
    }
    .ai-agent-framework .title {
      font-size: 32px;
      font-weight: 600;
      fill: #2c3e50;
    }
    .ai-agent-framework .raw-data {
      animation: pulse-l 2s infinite alternate;
    }
    .ai-agent-framework .raw-data-text {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    .ai-agent-framework .raw-data:hover .raw-data-text {
      opacity: 1;
    }
    @keyframes pulse-s {
      from { transform: scale(0.99); }
      to { transform: scale(1.01); }
    }
    @keyframes pulse-l {
      from { transform: scale(0.97); }
      to { transform: scale(1.03); }
    }
    .ai-agent-framework .raw-data-text {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    .ai-agent-framework .raw-data:hover .raw-data-text {
      opacity: 1;
    }
    .ai-agent-framework .raw-data-split,
    .ai-agent-framework .raw-data-circle {
      transition: all 0.5s ease;
    }
  `;

  return (
    <div className="ai-agent-framework" id={targetElementId}>
      <style>{styles}</style>
      <svg viewBox="0 0 900 900" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="connection-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "#4CAF50", stopOpacity: 1 }} />
            <stop offset="50%" style={{ stopColor: "#2196F3", stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: "#E91E63", stopOpacity: 1 }} />
          </linearGradient>
          <filter id="glow">
            <feGaussianBlur stdDeviation="2.5" result="coloredBlur" />
            <feMerge>
              <feMergeNode in="coloredBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>

        {/* Raw Data (Center) */}
        <g
          className="raw-data"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <>
              <circle cx="450" cy="385" r="65" fill="#FFA000" className="raw-data-split" />
              <circle cx="450" cy="525" r="65" fill="#FFB300" className="raw-data-split" />
              <text x="450" y="390" fontSize="clamp(28px, 3vw, 30px)" fill="#1A1A1A" textAnchor="middle">Image</text>
              <text x="450" y="530" fontSize="clamp(28px, 3vw, 30px)" fill="#1A1A1A" textAnchor="middle">Text</text>
            </>
          ) : (
            <>
              <circle cx="450" cy="450" r="130" fill="#FFC107" className="raw-data-circle" />
              <text x="450" y="455" fontSize="clamp(34px, 3vw, 36px)" fill="#333333" textAnchor="middle">Raw Data</text>
            </>
          )}
        </g>

        {/* Connections */}
        <path d="M450,130 A320,320 0 0,1 770,450" className="connection" stroke="url(#connection-gradient)" />
        <path d="M770,450 A320,320 0 0,1 450,770" className="connection" stroke="url(#connection-gradient)" />
        <path d="M450,770 A320,320 0 0,1 130,450" className="connection" stroke="url(#connection-gradient)" />
        <path d="M130,450 A320,320 0 0,1 450,130" className="connection" stroke="url(#connection-gradient)" />

        {/* Level 1: Data Structuring */}
        <g>
          <circle cx="450" cy="130" r="120" fill="#449d48" className="level-circle" />
          <text x="450" y="110" className="level-title" fill="#ffffff" textAnchor="middle">Level 1:</text>
          <text x="450" y="150" className="level-title" fill="#ffffff" textAnchor="middle">Data Structuring</text>

          {/* Level 1 Details */}
          <rect x="320" y="30" width="290" height="110" rx="20" fill="#449d48" className="process-rect" />
          <rect x="270" y="150" width="390" height="110" rx="20" fill="#367d39" className="benefits-rect" />
          <g className="level-details">
            <text x="345" y="60" className="detail-text" fill="#ffffff">• Data Ingestion</text>
            <text x="345" y="90" className="detail-text" fill="#ffffff">• Data Cleaning</text>
            <text x="345" y="120" className="detail-text" fill="#ffffff">• Data Transformation</text>
            <text x="295" y="180" className="detail-text" fill="#ffffff" fontWeight="500">Benefits:</text>
            <text x="295" y="215" className="detail-text" fill="#ffffff">• Enhanced Chatbot Functionality</text>
            <text x="295" y="240" className="detail-text" fill="#ffffff">• Knowledge Base Creation</text>
          </g>
        </g>

        {/* Level 2: Insight Extraction */}
        <g>
          <circle cx="770" cy="450" r="120" fill="#2196F3" className="level-circle" />
          <text x="770" y="430" className="level-title" fill="#ffffff" textAnchor="middle">Level 2:</text>
          <text x="770" y="470" className="level-title" fill="#ffffff" textAnchor="middle">Insight Extraction</text>

          {/* Level 2 Details */}
          <rect x="600" y="345" width="290" height="110" rx="20" fill="#2196F3" className="process-rect" />
          <rect x="520" y="460" width="370" height="110" rx="20" fill="#1a78c2" className="benefits-rect" />
          <g className="level-details">
            <text x="625" y="380" className="detail-text" fill="#ffffff">• Pattern Recognition</text>
            <text x="625" y="410" className="detail-text" fill="#ffffff">• Task-Specific Analysis</text>
            <text x="625" y="440" className="detail-text" fill="#ffffff">• Feedback Loop</text>
            <text x="540" y="495" className="detail-text" fill="#ffffff" fontWeight="500">Benefits:</text>
            <text x="540" y="525" className="detail-text" fill="#ffffff">• Automation of Complex Tasks</text>
            <text x="540" y="555" className="detail-text" fill="#ffffff">• Data-Driven Decision Making</text>
          </g>
        </g>

        {/* Level 3: Predictive Analysis */}
        <g>
          <circle cx="450" cy="770" r="120" fill="#E91E63" className="level-circle" />
          <text x="450" y="750" className="level-title" fill="#ffffff" textAnchor="middle">Level 3:</text>
          <text x="450" y="790" className="level-title" fill="#ffffff" textAnchor="middle">Predictive</text>
          <text x="450" y="825" className="level-title" fill="#ffffff" textAnchor="middle">Analysis</text>

          {/* Level 3 Details */}
          <rect x="340" y="670" width="260" height="90" rx="20" fill="#E91E63" className="process-rect" />
          <rect x="300" y="770" width="330" height="120" rx="20" fill="#ba184f" className="benefits-rect" />
          <g className="level-details">
            <text x="355" y="710" className="detail-text" fill="#ffffff">• Predictive Modeling</text>
            <text x="355" y="740" className="detail-text" fill="#ffffff">• Scenario Simulation</text>
            <text x="335" y="810" className="detail-text" fill="#ffffff" fontWeight="500">Benefits:</text>
            <text x="335" y="840" className="detail-text" fill="#ffffff">• Proactive Maintenance</text>
            <text x="335" y="870" className="detail-text" fill="#ffffff">• Resource Optimization</text>
          </g>
        </g>

        {/* Level 4: User Interaction Enhancement */}
        <g>
          <circle cx="130" cy="450" r="120" fill="#9C27B0" className="level-circle" />
          <text x="130" y="410" className="level-title" fill="#ffffff" textAnchor="middle">Level 4:</text>
          <text x="130" y="450" className="level-title" fill="#ffffff" textAnchor="middle">User Interaction</text>
          <text x="130" y="485" className="level-title" fill="#ffffff" textAnchor="middle">Enhancement</text>

          {/* Level 4 Details */}
          <rect x="10" y="360" width="370" height="90" rx="20" fill="#9C27B0" className="process-rect" />
          <rect x="10" y="460" width="330" height="115" rx="20" fill="#7c1f8c" className="benefits-rect" />
          <g className="level-details">
            <text x="25" y="395" className="detail-text" fill="#ffffff">• Natural Language Processing</text>
            <text x="25" y="425" className="detail-text" fill="#ffffff">• Personalization</text>
            <text x="25" y="495" className="detail-text" fill="#ffffff" fontWeight="500">Benefits:</text>
            <text x="25" y="525" className="detail-text" fill="#ffffff">• Intuitive User Experience</text>
            <text x="25" y="555" className="detail-text" fill="#ffffff">• Increased Adoption</text>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default MultiLevelDataGraph;
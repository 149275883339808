import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import {
    AiOutlineHome,
} from "react-icons/ai";
import { IoNewspaperOutline, IoBusinessOutline } from "react-icons/io5";

function NavBar() {
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollHandler);

    return (
        <Navbar
            expanded={expand}
            fixed="top"
            expand="md"
            className={navColour ? "sticky" : "navbar"}
        >
        <Container>
            <Navbar.Brand href="/" className="d-flex">
                <img src={logo} className="img-fluid logo" alt="brand" />
            </Navbar.Brand>
            <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() => {
                    updateExpanded(expand ? false : "expanded");
                }}
            >
                <span></span>
                <span></span>
                <span></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto" defaultActiveKey="#home">
                    <Nav.Item>
                        <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                            <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
                        </Nav.Link>
                    </Nav.Item>

                    {/* <Nav.Item>
                        <Nav.Link
                            as={Link}
                            to="/readnews4u"
                            onClick={() => updateExpanded(false)}
                        >
                            <IoNewspaperOutline style={{ marginBottom: "2px" }} /> ReadNews4U
                        </Nav.Link>
                    </Nav.Item> */}

                    <Nav.Item>
                        <Nav.Link
                            as={Link}
                            to="https://invests4u.com/home"
                            target="_blank"
                            onClick={() => updateExpanded(false)}
                        >
                            <IoBusinessOutline style={{ marginBottom: "2px" }} /> Invests4U
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="fork-btn">
                        <Button
                            href="mailto:info@speedai.co"
                            target="_blank"
                            className="fork-btn-inner"
                        >
                            {"Contact Us"}
                        </Button>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    );
}

export default NavBar;

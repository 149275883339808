import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Lottie from 'react-lottie';
import AOS from "aos";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { EffectCards, Zoom } from 'swiper/modules';

import {
    Button,
 } from '@mui/material';

import accountingData from "../../Assets/accounting.json"
import KF6 from "../../Assets/invest4u_compare.png";
import KF4 from "../../Assets/invest4u_analysis.png";
import KF2 from "../../Assets/invest4u_home.png";
import KF5 from "../../Assets/invest4u_income.png";
import KF3 from "../../Assets/invest4u_info.png";
import KF7 from "../../Assets/invest4u_report.png";

import AccordionItem from "../CustomeAccordion/AccordionItem"
import AccordionWrapper from "../CustomeAccordion/AccordionWrapper"

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../node_modules/aos/dist/aos.css";
import "../CustomeAccordion/Accordion.css";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/zoom';
import "../../swiper_card.css"

AOS.init({
    once: true
});

const Introduction = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: accountingData,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }};

    return (
        <Container fluid className="home-section" id="Invest4U">
            <Container className="home-content">
                <Row>
                    <Col md={5} className="sum4u-introduction-img" 
                        data-aos="fade-right"
                        data-aos-duration="2000"
                        data-aos-delay="000"
                        style={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Lottie 
                            options={defaultOptions}
                            height={400}
                            width={400}
                        />
                    </Col>

                    <Col md={7} className="home-challenges-description"
                        data-aos="fade-left"
                        data-aos-duration="2000"
                        data-aos-delay="000"
                    >
                        <h1 style={{ fontSize: "1.8em", paddingBottom: 10, color: "#fd7013" }}>
                            Unleash Investment Potential with Invests4U
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            In-depth financial analysis is essential for investors to gauge a company's 
                            long-term prospects and management competence, despite the challenges of complex 
                            reporting and industry nuances.
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10, paddingBottom: 20 }}>
                            Our cutting-edge platform harnesses data and AI to provide investors with the 
                            insights needed to navigate the market and make informed decisions, giving you 
                            a competitive edge through:
                        </h1>
                        <AccordionWrapper>
                            <AccordionItem
                                index={0} 
                                title={"Detailed Company Financials"} 
                                description={
                                    "Access comprehensive income statements, balance sheets, and cash flow data to uncover the true potential of your investment opportunities."
                                }
                            />
                            <AccordionItem
                                index={1} 
                                title={"Powerful AI Analysis"} 
                                description={
                                    "Our advanced algorithms analyze the data, providing valuable insights into financial health, performance, and growth prospects. Identify the most promising investments with greater accuracy."
                                }
                            />
                            <AccordionItem
                                index={2} 
                                title={"Educational Resources"} 
                                description={
                                    "Guides and educational materials tailored for all experience levels. Navigate the complex world of investing with confidence and make informed decisions."
                                }
                            />
                        </AccordionWrapper>
                        <Button 
                            href="https://www.invests4u.com/" 
                            target="_blank" 
                            className="fork-btn-inner"
                            style={{ 
                                height: 60,
                                color: 'white',
                                fontWeight: 600,
                                top: 20,
                            }}
                        >
                            {"Check it out"}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}


const KeyFeatures = () => {
    const images = [ KF2, KF3, KF4, KF5, KF7, KF6 ];

    return (
        <Container fluid className="home-section" id="Invest4U">
            <Container className="home-content">
                <Swiper
                    effect={'cards'}
                    grabCursor={true}
                    zoom={true}
                    modules={[EffectCards, Zoom]}
                    className="mySwiper"
                >
                    {images.map((item, index) => {
                        return (
                            <SwiperSlide key={index} pt={0}>
                                <div className="swiper-zoom-container">
                                    <img key={index} src={item} alt="na" width="100%" loading="lazy" />
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Container>
        </Container>
    );
}

function Invest4U() {
    return (
        <div className="home-section">
            <Introduction />
            <KeyFeatures />
        </div>
    );
}

export default Invest4U;

import React from 'react';
import * as joint from 'jointjs';

class WorkflowDiagram extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.graph = new joint.dia.Graph();
    this.paper = null;
    this.elements = {};
    this.state = {
      popup: {
        isOpen: false,
        content: '',
        position: { x: 0, y: 0 }
      }
    };
    this.popupTimeoutId = null;
    this.timeout = 4000; // 5s
  }

  componentDidMount() {
    this.initialize();
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
    this.clearPopupTimeout();
  }

  initialize() {
    this.paper = new joint.dia.Paper({
      el: this.containerRef.current,
      model: this.graph,
      width: 420,
      height: 500,
      gridSize: 10,
      drawGrid: true,
      background: {
        color: 'rgba(0, 0, 0, 0.)'
      }
    });

    this.createNodes();
    this.createLinks();
    this.addEventListeners();
  }

  getPopupContent(label) {
    const moduleInfo = {
      'Input \nProcessing': 'Understands user input, interprets context, and classifies intent.\nBenefits: Enhances customer satisfaction by delivering accurate and timely responses.',
      'Planning \n& Reasoning': 'Breaks down tasks, sets goals, infers logic, and makes decisions.\nBenefits: Automates complex problem-solving, enabling teams to focus on strategic initiatives.',
      'Response \nGeneration': 'Crafts natural language responses, summarizes content, and generates multimodal outputs.\nBenefits: Engages users with clear and personalized interactions.',
      'Standardization \n& QA': 'Formats outputs, checks consistency, and ensures ethical compliance.\nBenefits: Maintains high-quality interactions and safeguards brand reputation.',
      'Tooling \n& Execution': 'Integrates APIs, connects external services, manipulates data, and performs computations.\nBenefits: Boosts operational efficiency by streamlining processes.',
      'Knowledge Base': 'Retrieves information, conducts semantic searches, and navigates knowledge graphs.\nBenefits: Ensures relevant and timely information, empowering informed decision-making.',
      'Logging \n& Monitoring': 'Tracks performance metrics, manages errors, and analyzes usage data.\nBenefits: Provides insights for optimization and data-driven decision-making.',
      'Learning \n& Adaptation': 'Processes feedback, fine-tunes models, and supports continuous improvement.\nBenefits: Evolves the AI to better meet user needs, enhancing overall satisfaction.',
    };
  
    return moduleInfo[label] || '';
  }

  createNodes() {
    const nodes = [
      { id: 0, label: '', icon: '👤', shape: 'circle', x: -40, y: 230 },
      { id: 1, label: 'Input \nProcessing', shape: 'rectangle', x: 80, y: 10 },
      { id: 2, label: 'Planning \n& Reasoning', shape: 'rectangle', x: 80, y: 160 },
      { id: 3, label: 'Response \nGeneration', shape: 'rectangle', x: 80, y: 310 },
      { id: 4, label: 'Standardization \n& QA', shape: 'rectangle', x: 80, y: 430 },
      { id: 6, label: 'Tooling \n& Execution', shape: 'rectangle', x: 260, y: 10 },
      { id: 7, label: 'Knowledge Base', shape: 'cylinder', x: 260, y: 160 },
      { id: 8, label: 'Logging \n& Monitoring', shape: 'rectangle', x: 260, y: 430 },
      { id: 9, label: 'Learning \n& Adaptation', shape: 'rectangle', x: 260, y: 310 },
    ];

    nodes.forEach((node) => {
      this.elements[node.id] = this.createShape(node.x, node.y, node.label, node.icon, node.shape);
      this.graph.addCell(this.elements[node.id]);
    });
  }

  createLinks() {
    const links = [
      { source: 0, target: 1, color: '#e74c3c' },
      { source: 1, target: 2, color: '#3498db' },
      { source: 2, target: 3, color: '#3498db' },
      { source: 3, target: 4, color: '#3498db' },
      { source: 4, target: 0, color: '#e74c3c' },
      { source: 2, target: 6, color: '#2ecc71' },
      { source: 6, target: 7, color: '#2ecc71' },
      { source: 4, target: 8, color: '#9b59b6' },
      { source: 8, target: 9, color: '#9b59b6' },
      { source: 9, target: 7, color: '#2ecc71' },
    ];

    links.forEach(link => {
      const l = new joint.shapes.standard.Link({
        source: { id: this.elements[link.source].id },
        target: { id: this.elements[link.target].id },
        router: { name: 'manhattan' },
        connector: { name: 'rounded' },
        attrs: {
          line: {
            stroke: link.color,
            strokeWidth: 2,
            strokeDasharray: link.color === '#2ecc71' ? '3 3' : 'none'
          }
        }
      });

      const highlighter = this.createHighlighter(link);
      l.set('tool', highlighter);
      l.addTo(this.graph);
    });
  }

  createShape(x, y, label, icon, shape, fillColor='#3498db', 
              strokeColor='#2980b9', bgColor='rgba(0,0,0,0.3)', strokeWidth=2, 
              textColor='white', fontSize=14, fontWeight='bold') {
    var shapeObj;
    if (label === 'Knowledge Base') {
      textColor = '#3498db';
    }
    var commonAttrs = {
      body: {
        fill: fillColor,
        stroke: strokeColor,
        strokeWidth: strokeWidth,
        filter: {
          name: 'dropShadow',
          args: {
            dx: 2,
            dy: 2,
            blur: 3,
            color: bgColor
          }
        }
      },
      label: {
        text: icon ? `${icon} ${label}` : label,
        fill: textColor,
        fontSize: fontSize,
        fontWeight: fontWeight
      }
    };

    switch (shape) {
      case 'rectangle':
        shapeObj = new joint.shapes.standard.Rectangle();
        break;
      case 'circle':
        shapeObj = new joint.shapes.standard.Circle();
        break;
      case 'ellipse':
        shapeObj = new joint.shapes.standard.Ellipse();
        break;
      case 'polygon':
        shapeObj = new joint.shapes.standard.Polygon();
        shapeObj.attr('body/refPoints', '0,10 10,0 20,10 10,20');
        break;
      case 'cylinder':
        shapeObj = new joint.shapes.standard.Cylinder();
        break;
      default:
        shapeObj = new joint.shapes.standard.Rectangle();
    }

    shapeObj.position(x, y);
    shapeObj.resize(140, 50);
    shapeObj.attr(commonAttrs);

    return shapeObj;
  }

  createHighlighter(link) {
    return new joint.shapes.standard.Path({
      size: { width: 10, height: 10 },
      attrs: {
        path: {
          d: 'M 0 5 10 5',
          strokeWidth: 6,
          stroke: link.color,
          opacity: 0.5,
        }
      }
    });
  }

  addEventListeners() {
    this.paper.on('cell:pointerclick', (cellView) => {
      cellView.model.toFront();
    });

    this.paper.on('cell:mouseover', (cellView) => {
      if (cellView.model.isElement()) {
        this.highlightConnectedElements(cellView.model, true);
      } else if (cellView.model.isLink()) {
        this.highlightLink(cellView.model, true);
      }
    });

    this.paper.on('cell:mouseout', (cellView) => {
      if (cellView.model.isElement()) {
        this.highlightConnectedElements(cellView.model, false);
      } else if (cellView.model.isLink()) {
        this.highlightLink(cellView.model, false);
      }
    });

    // this.paper.on('cell:pointerup', (cellView) => {
    //   this.updateNodeLocation(cellView);
    // });

    this.paper.on('element:pointerclick', (cellView, evt, x, y) => {
      const element = cellView.model;
      const label = element.attr('label/text');
      const content = this.getPopupContent(label);
      if (content) {
        this.showPopup(content, { x, y });
      }
    });
  
    this.paper.on('blank:pointerclick', () => {
      this.hidePopup();
    });
  }

  highlightConnectedElements(element, highlight) {
    const connectedLinks = this.graph.getConnectedLinks(element);
    connectedLinks.forEach((link) => {
      const sourceElement = link.getSourceElement();
      const targetElement = link.getTargetElement();
      this.highlightElement(sourceElement, highlight);
      this.highlightElement(targetElement, highlight);
      this.highlightLink(link, highlight);
    });
  }

  highlightElement(element, highlight) {
    var view = this.paper.findViewByModel(element);
    if (view) {
      if (highlight) {
        view.highlight();
        element.attr('body/fill', '#ff7f50');
      } else {
        view.unhighlight();
        element.attr('body/fill', '#3498db');
      }
    }
  }

  highlightLink(link, highlight) {
    if (highlight) {
      link.attr({
        line: {
          strokeWidth: 4,
          stroke: link.attr('line/stroke'),
          strokeDasharray: link.attr('line/stroke') === '#2ecc71' ? '3 3' : 'none'
        }
      });
    } else {
      link.attr({
        line: {
          strokeWidth: 2,
          stroke: link.attr('line/stroke'),
          strokeDasharray: link.attr('line/stroke') === '#2ecc71' ? '3 3' : 'none'
        }
      });
    }
  }

  updateNodeLocation(cellView) {
    if (cellView.model.isElement()) {
      var position = cellView.model.position();
      console.log(`Node "${cellView.model.attr('label/text')}" moved to: x=${position.x}, y=${position.y}`);
    }
  }

  handleDocumentClick = (event) => {
    if (this.state.popup.isOpen && !this.containerRef.current.contains(event.target)) {
      this.hidePopup();
    }
  }

  showPopup(content, position) {
    const paperRect = this.paper.getArea();
    const popupWidth = 300; // Assuming a fixed width for the popup
    const popupHeight = 150; // Estimate a height for the popup
  
    let x = position.x;
    let y = position.y;
  
    // Adjust x-position if popup would overflow right edge
    if (x + popupWidth > paperRect.x + paperRect.width) {
      x = paperRect.x + paperRect.width - popupWidth;
    }
  
    // Adjust y-position if popup would overflow bottom edge
    if (y + popupHeight > paperRect.y + paperRect.height) {
      y = paperRect.y + paperRect.height - popupHeight;
    }
  
    // Ensure x and y are not less than paper's top-left corner
    x = Math.max(x, paperRect.x);
    y = Math.max(y, paperRect.y);
  
    this.setState({
      popup: {
        isOpen: true,
        content: content,
        position: { x, y }
      }
    });

    // Set a new timeout
    this.popupTimeoutId = setTimeout(() => {
      this.hidePopup();
    }, this.timeout);
  }

  hidePopup() {
    this.clearPopupTimeout();

    this.setState({
      popup: {
        isOpen: false,
        content: '',
        position: { x: 0, y: 0 }
      }
    });
  }

  clearPopupTimeout() {
    if (this.popupTimeoutId) {
      clearTimeout(this.popupTimeoutId);
      this.popupTimeoutId = null;
    }
  }

  renderPopup() {
    const { isOpen, content, position } = this.state.popup;
    if (!isOpen) return null;
  
    const style = {
      position: 'absolute',
      left: position.x + 'px',
      top: position.y + 'px',
      backgroundColor: '#f8f9fa',
      border: 'none',
      padding: '15px',
      borderRadius: '8px',
      boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
      zIndex: 1000,
      maxWidth: '320px',
      maxHeight: '200px',
      overflowY: 'auto',
      lineHeight: '1.3',
      color: '#333',
    };
  
    const headingStyle = {
      fontSize: '1.1em',
      fontWeight: 'bold',
      marginBottom: '10px',
      color: '#2c3e50',
    };
  
    const contentStyle = {
      whiteSpace: 'pre-wrap',
      margin: 0,
    };
  
    const [heading, ...contentLines] = content.split('\n');
  
    return (
      <div style={style}>
        <div style={headingStyle}>{heading}</div>
        <pre style={contentStyle}>{contentLines.join('\n')}</pre>
      </div>
    );
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div ref={this.containerRef} style={{ width: '100%', height: '500px' }}></div>
        {this.renderPopup()}
      </div>
    );
  }
}

export default WorkflowDiagram;
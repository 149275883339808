import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Lottie from 'react-lottie';
import AOS from "aos";

import consultingData from "../../Assets/consulting.json"

import "../../../node_modules/aos/dist/aos.css";
import "../CustomeAccordion/Accordion.css";

AOS.init({
    once: true
});

function ConsultingSection() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: consultingData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Container fluid className="home-section" id="challenges">
            <Container>
                <Row>
                    <Col lg={4} className="sum4u-introduction-img"
                        data-aos="fade-right"
                        data-aos-duration="2000"
                        data-aos-delay="000"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Lottie
                            options={defaultOptions}
                            height={400}
                            width={400}
                        />
                    </Col>

                    <Col lg={8} className="home-challenges-description"
                        data-aos="fade-left"
                        data-aos-duration="2000"
                        data-aos-delay="000"
                    >
                        <h1 style={{ fontSize: "2.3em", paddingBottom: 10, color: "#fd7013" }}>
                            Comprehensive AI Consulting
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            Are you ready to harness the power of AI to streamline your operations
                            and unlock new growth opportunities? At Speed AI, we understand that
                            every organization faces distinct challenges and opportunities when it
                            comes to leveraging transformative AI technologies.
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            That's why we offer comprehensive consulting services to guide you
                            through every step of developing and integrating custom AI applications
                            tailored to your unique needs. Leveraging our in-house AI framework,
                            we have the agility to adapt our solutions to the specific requirements
                            of your use cases.
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            Our team of AI experts will work closely with you to:
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            <ul>
                                <li>Deeply understand your business objectives, workflows, and pain points.</li>
                                <li>Identify the optimal AI use cases that can drive measurable impact.</li>
                                <li>Design a scalable, integrated AI solution tailored to your requirements.</li>
                                <li>Seamlessly implement the technology and provide ongoing support.</li>
                                <li>Continuously optimize performance and unlock new opportunities</li>
                            </ul>
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            Partner with us to elevate your operations, enhance decision-making, and
                            outpace the competition. Reach out to us
                            <a href="mailto:info@speedai.co"> info@speedai.co </a>
                            to get started. We're here to help you overcome the barriers and embrace
                            the future of AI-driven success.
                        </h1>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default ConsultingSection;
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ShieldIcon from '@mui/icons-material/Shield';

import PrivacyDialog from "./PrivacyPolicy";
import TandADialog from "./TandA";

function Footer() {
    let date = new Date();
    let year = date.getFullYear();

    const [openTerms, setOpenTerms] = React.useState(false);
    const [openPolicy, setOpenPolicy] = React.useState(false);

    return (
        <Container fluid className="footer">
            <Row>
                <hr className="animate__animated animate__fadeIn"></hr>
                <Col md="4" className="footer-copywright">
                </Col>
                <Col md="4" className="footer-copywright">
                    <h3>
                        {" "}
                        &copy; {year} Speed AI LLC. All rights reserved.
                    </h3>
                </Col>
                <Col md="4" className="footer-body">
                    <ul className="footer-icons">
                        <li className="social-icons">
                            <a
                                href="mailto:info@speedai.co?Subject=Questions"
                                target="_blank"
                                style={{ color: "black" }}
                                rel="noopener noreferrer"
                            >
                                <EmailIcon />
                            </a>
                        </li>
                        {/* <li className="social-icons">
                            <a
                                href="https://www.linkedin.com/"
                                style={{ color: "black" }}
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <LinkedInIcon />
                            </a>
                        </li> */}
                        <li className="social-icons">
                            <a
                                style={{ color: "black" }}
                                rel="noopener noreferrer"
                                onClick={ () => setOpenTerms(true) }
                            >
                                <HandshakeIcon />
                            </a>
                        </li>
                        <li className="social-icons">
                            <a
                                style={{ color: "black" }}
                                rel="noopener noreferrer"
                                onClick={ () => setOpenPolicy(true) }
                            >
                                <ShieldIcon />
                            </a>
                        </li>
                    </ul>
                </Col>
            </Row>
            <TandADialog 
                open={ openTerms }
                setOpen={ setOpenTerms }
            />
            <PrivacyDialog 
                open={ openPolicy }
                setOpen={ setOpenPolicy }
            />
        </Container>
    );
}

export default Footer;

import React from "react";

import AboutUs from "./AboutUs.js";
import ConsultingSection from './Consulting.js';
import MultiLevelDataSection from "../MultiLevelData/index.js";
import WorkflowSection from "../Workflow/index.js";

function Home() {
    return (
        <section>
            <AboutUs />
            <WorkflowSection />
            <MultiLevelDataSection />
            <ConsultingSection />
        </section>
    );
}

export default Home;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";

import "../../../node_modules/aos/dist/aos.css";
import "../CustomeAccordion/Accordion.css";
import MultiLevelDataGraph from "../MultiLevelDataGraph";

AOS.init({
  once: true
});

function MultiLevelDataSection() {
  return (
    <Container fluid className="home-section" id="challenges">
      <Container>
        <Row>
          <Col lg={6} className="home-challenges-description"
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-delay="000"
          >
            <h1 style={{ fontSize: "2.3em", fontWeight: 400, paddingBottom: 10, color: "#fd7013" }}>
              Maximize Your Data Assets
            </h1>
            <h1 style={{ fontSize: "1.4em", textAlign: 'left', padding: 20 }}>
              Our advanced data processing framework empowers your business by transforming raw data 
              into actionable insights, enhancing decision-making capabilities, optimizing operations 
              and resource allocation, and improving user experience and engagement.
            </h1>

            <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 20, lineHeight: '1.4' }}>
              Our solutions provide significant benefits, including:
              <ul>
                <li><span className="orange"><b>Time and Cost Savings</b></span>: Automate processes and make informed decisions quickly</li>
                <li><span className="orange"><b>Improved Efficiency</b></span>: Optimize operations based on data-driven insights</li>
                <li><span className="orange"><b>Risk Mitigation</b></span>: Anticipate and prevent issues before they occur</li>
                <li><span className="orange"><b>Enhanced User Satisfaction</b></span>: Provide personalized, intuitive interactions</li>
                <li><span className="orange"><b>Competitive Advantage</b></span>: Leverage advanced AI capabilities to stay ahead in your industry</li>
              </ul>
            </h1>
          </Col>

          <Col lg={5} className="sum4u-introduction-img"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-delay="000"
            style={{
              // display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center',
              // height: '100vh',
              // paddingTop: '0px'
            }}
          >
            {/* <div style={{ width: '100%', height: '100%', alignContent: 'center' }}>
              <MultiLevelDataGraph />
            </div> */}
            <MultiLevelDataGraph />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
export default MultiLevelDataSection;